import "./App.scss";
import "leaflet/dist/leaflet.css";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { Storage } from "@aws-amplify/storage";
import { Routes, Route, Link } from "react-router-dom";
import AppContext from "./Shared/Context/AppContext";
import Summary from "./Pages/Summary";
import Breakdowns from "./Pages/Breakdowns";
import { useEffect, useState, useRef } from "react";
import { fromCSV } from "arquero";
import { ObjectParam } from "use-query-params";
import useAllQueryParams from "./Shared/Hooks/useAllQueryParams";
import { encodeQueryParams } from "serialize-query-params";
import { stringify } from "query-string";
import {
  filterCardCountTable,
  GenerateLabelsFromColumnValues,
} from "./Shared/Utils.js";
import L from "leaflet";

import {
  county_fips,
  GeoTypes,
  AgeRanges,
  Races,
  Genders,
} from "./Shared/Labels";
import LoadingIcon from "./Components/LoadingIcon";

(function () {
  // fix leaflet ugly one pixel issue.
  var originalInitTile = L.GridLayer.prototype._initTile;
  L.GridLayer.include({
    _initTile: function (tile) {
      originalInitTile.call(this, tile);

      var tileSize = this.getTileSize();

      tile.style.width = tileSize.x + 1 + "px";
      tile.style.height = tileSize.y + 1 + "px";
    },
  });
})();

Storage.configure({
  AWSS3: {
    bucket: "one-az.targetsmart.com",
    region: "us-east-1",
  },
  customPrefix: {
    public: "data/",
  },
});

let aqFormatOpts = {
  parse: {
    AIANNHCE: String,
    county_fips: (val) => {
      let parsedVal = val.toString();
      parsedVal = parsedVal === "04" ? "04000" : parsedVal;
      return parsedVal;
    },
    completed_date: (val) => {
      return new Date(val).getTime();
    },
    gender: String,
    race: String,
    age_range: String,
    location_type: String,
    city: String,
    matched: String,
    organization: String,
  },
};

function App({ signOut, user }) {
  const [errorFetchedChecker, setErrorFetchedChecker] = useState(false);
  let CardCountTableRef = useRef(null);
  let LabelRef = useRef(null);
  let GeoTypeLabelsRef = useRef(null);
  let [loading, setLoading] = useState(true);
  let [Filters] = useAllQueryParams("filters", ObjectParam);
  let [FilteredCardCountTable, setFilteredCardCountTable] = useState(null);

  useEffect(() => {
    if (CardCountTableRef.current === null) {
      Storage.get("CardCountTable_latest.csv", { download: true })
        .then((file) => {
          file.Body.text().then((t) => {
            CardCountTableRef.current = fromCSV(t, aqFormatOpts);
            console.log(CardCountTableRef.current);
            LabelRef.current = {
              GeoTypes,
              AgeRanges,
              Races,
              Genders,
              LocationType: GenerateLabelsFromColumnValues(
                CardCountTableRef.current,
                "vr_Category"
              ),
              Organization: GenerateLabelsFromColumnValues(
                CardCountTableRef.current,
                "organization"
              ),
            };
            GeoTypeLabelsRef.current = {
              county_fips: county_fips,
              city: GenerateLabelsFromColumnValues(
                CardCountTableRef.current,
                "city"
              ),
            };
            setFilteredCardCountTable(
              filterCardCountTable(CardCountTableRef.current, Filters)
            );
            setLoading(false);
          });
        })
        .catch((error) => {
          // There seems to be something wrong with @aws-amplify/storage where it randonly fails throwing `axios-http-handler - Network Error`.
          // This is really hacky but it's the best solution I can find at the momeent. Try again until it works.
          console.log(error);
          setTimeout(() => {
            setErrorFetchedChecker((c) => !c);
          }, 200);
        });
    } else {
      setFilteredCardCountTable(
        filterCardCountTable(CardCountTableRef.current, Filters)
      );
    }
  }, [Filters, errorFetchedChecker]);

  // console.log();
  let filterString = !!Filters
    ? "?" +
      stringify(
        encodeQueryParams(
          {},
          Object.keys(Filters)
            .filter((key) => {
              return Filters[key] !== "any" && Filters[key] !== undefined;
            })
            .reduce((cur, key) => {
              return Object.assign(cur, { [key]: Filters[key] });
            }, {})
        )
      )
    : "";
  if (loading)
    return (
      <div className="d-flex align-items-center justify-content-center h-100 w-100 text-center">
        <div style={{ width: "5em" }}>
          <LoadingIcon></LoadingIcon>
          Loading...
        </div>
      </div>
    );
  return (
    <AppContext.Provider
      value={{
        Labels: LabelRef.current,
        GeoTypeLabels: GeoTypeLabelsRef.current,
        CardCountTable: CardCountTableRef.current,
        FilteredCardCountTable,
      }}
    >
      <div>
        <header className="d-flex align-items-center justify-content-between p-4 bg-dark">
          <nav>
            <Link className="mr-3 text-white" to={"/" + filterString}>
              Summary
            </Link>
            <Link
              className="mr-3 text-white"
              to={"/breakdowns/" + filterString}
            >
              Breakdowns
            </Link>
            {/* <Link className="mr-3 text-white" to="/monitoring">
              Monitoring
            </Link>
            <Link className="mr-3 text-white" to="/reports">
              Reports
            </Link> */}
          </nav>
          <button
            className="btn btn-sm btn-gray-200 text-gray-800"
            onClick={signOut}
          >
            Sign out
          </button>
        </header>
        <main>
          <Routes>
            <Route path="/" element={<Summary />}></Route>
            <Route path={"/breakdowns/*"} element={<Breakdowns />}></Route>
            <Route path="*" element={<div>404</div>}></Route>
          </Routes>
        </main>
      </div>
    </AppContext.Provider>
  );
}

export default withAuthenticator(App);
