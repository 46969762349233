import AppContext from "../Shared/Context/AppContext";
import Filters from "../Components/Filters";
import { useContext } from "react";
import * as aq from "arquero";
import * as d3 from "d3";
import TsHeatMap from "../Components/TsHeatMap";

import {
  LineChart,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";

export default function Summary() {
  let { FilteredCardCountTable } = useContext(AppContext);
  ////////////////////
  // compute sums
  ////////////////////
  const cardsSum = aq.agg(FilteredCardCountTable, aq.op.sum("Count")) || 0;

  const cardsMatchedSum =
    aq.agg(
      FilteredCardCountTable.filter((d) => d.matched === "TRUE"),
      aq.op.sum("Count")
    ) || 0;

  const providedPhoneSum =
    aq.agg(
      FilteredCardCountTable.filter((d) => d.provided_phone_number === "TRUE"),
      aq.op.sum("Count")
    ) || 0;

  const onPavSum =
    aq.agg(
      FilteredCardCountTable.filter((d) => d.pav === "Yes"),
      aq.op.sum("Count")
    ) || 0;

  ////////////////////
  // compute rates
  ////////////////////
  const cardsMatchRate = cardsMatchedSum / cardsSum || 0.0;
  const providedPhoneRate = providedPhoneSum / cardsSum || 0.0;
  const onPavRate = onPavSum / cardsMatchedSum || 0.0;

  return (
    <div className="p-4">
      <Filters />
      <div className="d-flex justify-content-between flex-column flex-md-row align-items-start">
        <div className="w-100" style={{ flexBasis: "500px" }}>
          <div className="flex-fill w-100">
            <TsHeatMap></TsHeatMap>
          </div>
        </div>

        <div className="flex-fill w-100 w-md-auto">
          <div className="d-flex justify-content-around align-items-center flex-fill">
            <div className="p-2 text-center">
              <div className="h4">{cardsSum.toLocaleString()}</div>
              <div className="label">Cards Collected</div>
            </div>
            <div className="p-2 text-center">
              <div className="h4">{d3.format(".1%")(cardsMatchRate)}</div>
              <div className="label">Match Rate</div>
            </div>
            <div className="p-2 text-center">
              <div className="h4">{d3.format(".1%")(providedPhoneRate)}</div>
              <div className="label">Provided Phone #</div>
            </div>
            <div className="p-2 text-center">
              <div className="h4">{d3.format(".1%")(onPavRate)}</div>
              <div className="label">On PEV List</div>
            </div>
          </div>
          <div className="w-100 pl-md-4">
            <CardCollectedOverTime></CardCollectedOverTime>
          </div>
        </div>
      </div>
    </div>
  );
}

function CardCollectedOverTime() {
  let { FilteredCardCountTable } = useContext(AppContext);
  let counts = FilteredCardCountTable.groupby("completed_date")
    .rollup({
      count: aq.op.sum("Count"),
    })
    .orderby("completed_date")
    .filter((d) => aq.op.valid(d.completed_date));
  let data = [];
  for (let index = 0; index < counts.numRows(); index++) {
    if (counts.get("completed_date", index) !== null)
      data.push({
        Date: counts.get("completed_date", index),
        Count: counts.get("count", index),
      });
  }

  return (
    <ResponsiveContainer aspect={16 / 9}>
      <LineChart
        width={400}
        height={200}
        data={data}
        margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="Date"
          domain={["auto", "auto"]}
          name="Time"
          tickFormatter={(dateTime) =>
            new Date(dateTime).toLocaleDateString("en-us", {
              weekday: "long",
              year: "numeric",
              month: "short",
              day: "numeric",
            })
          }
          type="number"
        />
        <YAxis width={40} tickFormatter={d3.format(".3~s")} />
        <Tooltip
          formatter={(value) => new Intl.NumberFormat("en").format(value)}
          labelFormatter={(dateTime) =>
            new Date(dateTime).toLocaleDateString("en-us", {
              weekday: "long",
              year: "numeric",
              month: "short",
              day: "numeric",
            })
          }
        />
        <Legend />
        <Line
          dot={false}
          type="monotone"
          dataKey="Count"
          name="Cards Collected"
          stroke="#003366"
        />
      </LineChart>
    </ResponsiveContainer>
  );
}
