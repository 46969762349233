import { useContext, useEffect } from "react";
import Filters from "../Components/Filters";
import AppContext from "../Shared/Context/AppContext";
import { AgeRanges, Genders, Races, county_fips } from "../Shared/Labels";
import { op, agg } from "arquero";
import * as d3 from "d3";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import useLocalStorage from "../Shared/Hooks/useLocalStorage";
import TsMap from "../Components/TsMap";

import AZCounties from "../Shared/GeoJSON/AZ-04-arizona-counties-geojson.json";
import { GeoJSON, useMap } from "react-leaflet";

import { scaleLinear } from "d3-scale";

const colorScale = scaleLinear().domain([0, 1]).range(["#ffffff", "#dd0000"]);

export default function Breakdowns() {
  let [metrics, setMetrics] = useLocalStorage("metrics", {
    collected: true,
    phoneProvided: true,
    matched: true,
    pav: true,
  });
  return (
    <div className="p-4">
      <Filters></Filters>
      <div className="d-flex justify-content-between flex-column flex-md-row align-items-start">
        <div className="w-100" style={{ flexBasis: "500px" }}>
          <div className="flex-fill w-100">
            <TsAZCounties></TsAZCounties>
          </div>
        </div>
        <div className="flex-fill w-100 w-md-auto px-4">
          <div className="d-flex justify-content-start align-items-center flex-wrap mb-3">
            <div className="mr-4 font-weight-bold ">Metrics:</div>
            <label className="mr-4 mb-0 text-nowrap">
              <input
                type="checkbox"
                name="cards-collected"
                id="cards-collected"
                className="mr-2"
                checked={metrics.collected}
                disabled={metrics.phoneProvided}
                onChange={(e) => {
                  setMetrics({ ...metrics, collected: e.target.checked });
                }}
              />
              Cards Collected
            </label>
            <label className="mr-4 mb-0 text-nowrap">
              <input
                type="checkbox"
                name="cards-phone-provided"
                id="cards-phone-provided"
                className="mr-2"
                disabled={!metrics.collected}
                checked={metrics.phoneProvided}
                onChange={(e) => {
                  setMetrics({ ...metrics, phoneProvided: e.target.checked });
                }}
              />
              Provided Phone #
            </label>
            <label className="mr-4 mb-0 text-nowrap">
              <input
                type="checkbox"
                name="cards-matched"
                id="cards-matched"
                className="mr-2"
                disabled={metrics.pav}
                checked={metrics.matched}
                onChange={(e) => {
                  setMetrics({ ...metrics, matched: e.target.checked });
                }}
              />
              Match Rate
            </label>
            <label className="mr-4 mb-0 text-nowrap">
              <input
                type="checkbox"
                name="cards-pav"
                id="cards-pav"
                className="mr-2"
                disabled={!metrics.matched}
                checked={metrics.pav}
                onChange={(e) => {
                  setMetrics({ ...metrics, pav: e.target.checked });
                }}
              />
              On PEV List
            </label>
          </div>
          <div className="w-100">
            <h2 className="h5">Cards Collected by Age</h2>
            <CardCollectedByField
              field="age_range"
              labels={AgeRanges}
              metrics={metrics}
            ></CardCollectedByField>
          </div>
          <div className="w-100">
            <h2 className="h5">Cards Collected by Gender</h2>
            <CardCollectedByField
              field="gender"
              labels={Genders}
              metrics={metrics}
            ></CardCollectedByField>
          </div>
          <div className="w-100">
            <h2 className="h5">Cards Collected by Race</h2>
            <CardCollectedByField
              field="race"
              labels={Races}
              metrics={metrics}
            ></CardCollectedByField>
          </div>
        </div>
      </div>
    </div>
  );
}

function TsAZCounties() {
  return (
    <TsMap>
      <AZCountyCardCountChoroplethLayers></AZCountyCardCountChoroplethLayers>
    </TsMap>
  );
}

function AZCountyCardCountChoroplethLayers() {
  let { FilteredCardCountTable } = useContext(AppContext);
  let CountyRollup = FilteredCardCountTable.groupby("county_fips").rollup({
    Count: op.sum("Count"),
  });
  let max = agg(CountyRollup, op.max("Count"));
  let map = useMap();
  useEffect(() => {
    map.eachLayer((layer) => {
      let { feature } = layer;
      if (!!feature) {
        let { GEOID } = feature.properties;
        let countyFilteredTable = CountyRollup.params({
          GEOID,
        }).filter((d, $) => d.county_fips === $.GEOID);
        let tooltipContent =
          "<div class='text-center'><strong>" +
          county_fips[GEOID] +
          "</strong><br/>" +
          (agg(countyFilteredTable, op.sum("Count")) || 0) +
          "</div>";

        if (!!layer.getTooltip()) {
          layer.setTooltipContent(tooltipContent);
        } else {
          layer.bindTooltip(tooltipContent);
        }
      }
    });
  }, [FilteredCardCountTable, CountyRollup, map]);
  return (
    <GeoJSON
      data={AZCounties}
      style={(feature) => {
        let { GEOID } = feature.properties;
        let countyFilteredTable = CountyRollup.params({
          GEOID,
        }).filter((d, $) => d.county_fips === $.GEOID);
        return {
          weight: 1,
          color: "#dd0000",
          opacity: 0.1,
          fillOpacity: 1,
          fillColor: colorScale(
            agg(countyFilteredTable, op.sum("Count")) / max || 0
          ),
        };
      }}
    ></GeoJSON>
  );
}

function AggChartData(table, field, dataLabel, data) {
  table
    .groupby(field)
    .rollup({
      count: op.sum("Count"),
    })
    .scan((index, d, stop) => {
      let value = d[field].data[index];
      // console.log(index, d, value);
      let rowIndex = data.findIndex((row) => {
        return row.key === value;
      });
      if (rowIndex < 0) return;
      // data[rowIndex].name = value === null ? "Unknown" : labels[value];
      data[rowIndex][dataLabel] = d["count"].data[index];
    });
  return data;
}

function CardCollectedByField({ field, labels, metrics }) {
  let { FilteredCardCountTable } = useContext(AppContext);

  let data = [
    { key: null, name: "Unknown" },
    ...Object.keys(labels).map((key, index) => {
      return { key, name: labels[key] };
    }),
  ];

  data = AggChartData(FilteredCardCountTable, field, "Cards Collected", data);
  data = AggChartData(
    FilteredCardCountTable.filter((d) => d.matched === "TRUE"),
    field,
    "Matched",
    data
  );
  data = AggChartData(
    FilteredCardCountTable.filter((d) => d.pav === "Yes"),
    field,
    "On PEV List",
    data
  );
  data = AggChartData(
    FilteredCardCountTable.filter((d) => d.provided_phone_number === "TRUE"),
    field,
    "Provided Phone #",
    data
  );
  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart data={data}>
        <XAxis dataKey="name" />
        <YAxis width={40} tickFormatter={d3.format(".3~s")} />
        <Tooltip
          cursor={{ fill: "#f7f7f7" }}
          content={({ active, payload, label }) => {
            if (active && payload && payload.length) {
              let total = 0;
              let matchedTotal = 0;
              let matchedIndex = 0;
              if (metrics.collected) {
                total = payload[0] ? payload[0].value : 0;
                matchedIndex++;
              }
              if (metrics.matched) {
                if (metrics.phoneProvided) {
                  matchedIndex++;
                }
                matchedTotal = payload[matchedIndex]
                  ? payload[matchedIndex].value
                  : 0;
              }
              return (
                <div className="bg-white p-3 rounded shadow-sm">
                  <div className="h4">{label}</div>
                  {payload.map((field, i) => {
                    return (
                      <div className="mb-3">
                        <div>
                          <span
                            className="d-inline-block mr-2 "
                            style={{
                              backgroundColor: field.fill,
                              height: ".8em",
                              width: ".8em",
                            }}
                          ></span>
                          <strong>{field.name}</strong>
                        </div>
                        {field.value}{" "}
                        {i !== 0 ? (
                          <span className="small">
                            (
                            {Math.round(
                              (field.value /
                                (i === matchedIndex + 1
                                  ? matchedTotal
                                  : total)) *
                                1000
                            ) / 10}
                            %)
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  })}
                </div>
              );
            }
            return <div></div>;
          }}
        />
        {metrics.collected && <Bar dataKey="Cards Collected" fill="#003366" />}
        {metrics.phoneProvided && (
          <Bar dataKey="Provided Phone #" fill="#ff9900" />
        )}
        {metrics.matched && <Bar dataKey="Matched" fill="#990000" />}
        {metrics.pav && <Bar dataKey="On PEV List" fill="#006600" />}
      </BarChart>
    </ResponsiveContainer>
  );
}
