export default function LoadingIcon() {
  return (
    <svg viewBox="0 0 150 150">
      <circle
        cx="50%"
        cy="50%"
        r="50"
        fill="transparent"
        className="loading-icon"
      ></circle>
    </svg>
  );
}
