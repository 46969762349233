import { useEffect } from "react";
import { useRef, useContext } from "react";
import L from "leaflet";
import "leaflet.heat";
import { MapContainer, TileLayer, useMap } from "react-leaflet";

export default function TsMap({ children }) {
  let mapContainerRef = useRef();
  return (
    <div
      className="flex-fill font-size-4 font-size-md-3 mb-2 rounded border border-gray-500"
      ref={mapContainerRef}
    >
      <MapContainer
        center={[34, -111]}
        zoom={6}
        style={{ height: "600px", width: "100%" }}
        spiderfyOnMaxZoom={false}
      >
        <TileLayer
          crossOrigin="anonymous"
          attribution='Map tiles by <a href="http://stamen.com">Stamen Design</a>, under <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a>. Data by <a href="http://openstreetmap.org">OpenStreetMap</a>, under <a href="http://www.openstreetmap.org/copyright">ODbL</a>.'
          url="https://stamen-tiles.a.ssl.fastly.net/toner/{z}/{x}/{y}.png"
        />
        {children}
      </MapContainer>
    </div>
  );
}
