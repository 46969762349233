import { useEffect } from "react";
import { useRef, useContext } from "react";
import L from "leaflet";
import "leaflet.heat";
import { useMap } from "react-leaflet";
import AppContext from "../Shared/Context/AppContext";

import { op } from "arquero";
import TsMap from "./TsMap";

export default function TsHeatMap() {
  return (
    <TsMap>
      <HeatMap></HeatMap>
    </TsMap>
  );
}

function HeatMap() {
  let { FilteredCardCountTable } = useContext(AppContext);
  let map = useMap();
  let heatMapLater = useRef();
  useEffect(() => {
    heatMapLater.current = L.heatLayer([]).addTo(map);
  }, []);

  useEffect(() => {
    if (!!heatMapLater.current) {
      let heat = FilteredCardCountTable.filter(
        (d) => op.valid(d.vr_latitude) && op.valid(d.vr_longitude)
      )
        .groupby("vr_latitude", "vr_longitude")
        .rollup({
          count: op.sum("Count"),
        })
        .objects();
      const points = heat
        ? heat.map((p) => {
            return [
              p["vr_latitude"] * 1,
              p["vr_longitude"] * 1,
              p["count"] * 1,
            ];
          })
        : [];
      heatMapLater.current.setLatLngs(points);
    }
  }, [FilteredCardCountTable]);

  return <></>;
}
