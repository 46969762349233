import { useQueryParams, withDefault, StringParam } from "use-query-params";

export default function useAllQueryParams() {
  const [query, setQuery] = useQueryParams({
    geo_type: withDefault(StringParam, "any"),
    geography: withDefault(StringParam, "any"),
    age_range: withDefault(StringParam, "any"),
    gender: withDefault(StringParam, "any"),
    race: withDefault(StringParam, "any"),
    location_type: withDefault(StringParam, "any"),
    organization: withDefault(StringParam, "any"),
    start_date: StringParam,
    end_date: StringParam,
  });

  let clearAllQueryParams = () => {
    setQuery({
      geo_type: undefined,
      geography: undefined,
      age_range: undefined,
      gender: undefined,
      race: undefined,
      location_type: undefined,
      organization: undefined,
      start_date: undefined,
      end_date: undefined,
    });
  };

  let setFilters = (query) => {
    let newQuery = {};
    Object.keys(query).map((key) => {
      newQuery[key] = query[key] === "any" ? undefined : query[key];
    });
    setQuery(newQuery);
  };

  return [query, setFilters, clearAllQueryParams];
}
