export const GeoTypes = {
  county_fips: "County",
  city: "City",
};

export const county_fips = {
  "04000": "Arizona (No County)",
  "04001": "Apache County",
  "04003": "Cochise County",
  "04005": "Coconino County",
  "04007": "Gila County",
  "04009": "Graham County",
  "04011": "Greenlee County",
  "04012": "La Paz County",
  "04013": "Maricopa County",
  "04015": "Mohave County",
  "04017": "Navajo County",
  "04019": "Pima County",
  "04021": "Pinal County",
  "04023": "Santa Cruz County",
  "04025": "Yavapai County",
  "04027": "Yuma County",
};

export const AgeRanges = {
  0: "-18",
  1: "18-29",
  2: "30-39",
  3: "40-49",
  4: "50-64",
  5: "65+",
};

export const Genders = {
  F: "Female",
  M: "Male",
  X: "Expansive",
};

export const Races = {
  "African-American": "African-American",
  Asian: "Asian",
  Caucasian: "Caucasian",
  Hispanic: "Hispanic",
  "Native American": "Native American",
};
